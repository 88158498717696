import useEnvStore from '@/store/modules/env';
import api from '@/utils/api';
import { defineStore } from 'pinia';

// NOTE: how to use this mixin add the below to computed section of where the mixin is imported
// useInternalShipping: {
//   get() {
//     this.getSetting('useInternalShipping') || false;
//   },
//   set(newValue) {
//     this.saveSetting('useInternalShipping', newValue);
//   },
// },

export default defineStore('settings', {
  state: () => ({
    settingsLoading: true,
    settingsData: [],
  }),
  getters: {
    getSetting: (state) => (key) => state.settingsData.find((s) => s.key === key)?.value,
  },
  actions: {
    async loadSettings(envData) {
      this.settingsLoading = true;

      const envStore = useEnvStore();

      this.settingsData = [];
      const res = await api.sparkApi({
        method: 'GET',
        url: '/v1/settings',
        body: {},
      });
      this.settingsData = res.data;

      const env = envData ?? envStore.env;

      if (env.store_id) {
        const shopifyRes = await api.get(`/${env.team.slugged_name}/${env.environment}/shopify/settings`);
        this.settingsData.push(...shopifyRes.data);
      }

      this.settingsLoading = false;
    },
    async saveSetting(key, value) {
      this.saveSettings([{ key, value }]);
    },
    async saveSettings(settings) {
      if (this.settingsLoading) {
        return;
      }
      this.settingsLoading = true;

      const envStore = useEnvStore();

      const shopifySettings = [];
      const generalSettings = [];

      for (const { key, value } of settings) {
        // Shopify specific settings use a different endpoint
        if ([
          'draftOrdersAutoComplete',
          'draftOrdersAutoCompleteAccountSetAsPaid',
          'draftOrdersAutoCompleteInvoiceSetAsPaid',
          'createDraftOrderOnCalculate',
        ].includes(key)) {
          shopifySettings.push({ key, value });
        } else {
          generalSettings.push({ key, value });
        }
      }

      if (shopifySettings.length) {
        await api.patch(`/${envStore.env.team.slugged_name}/${envStore.env.environment}/shopify/settings`, shopifySettings);
      }

      if (generalSettings.length) {
        await api.sparkApi({
          method: 'PATCH',
          url: '/v1/settings',
          body: generalSettings,
        });
      }

      this.loadSettings();
    },
    async saveBaseGroupSetting(key, value) {
      if (this.settingsLoading) {
        return;
      }
      this.settingsLoading = true;

      await api.sparkApi({
        method: 'PATCH',
        url: '/v1/settings/{slug}',
        params: { slug: 'base' },
        body: [{
          key,
          value,
        }],
      });

      this.loadSettings();
    },
  },
});
