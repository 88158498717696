import useEnvStore from '@/store/modules/env';
import notificationStore, { ERROR } from '@/store/modules/notifications';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/dashboard-api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401, 419].includes(error.response?.status) && error.request.responseURL.indexOf('/user') === -1) {
      // todo there's got to be a better way of doing this! without circular dependencies a problem
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }

    if (error.response?.status >= 500) {
      notificationStore.notify(
        'Error',
        "Sorry an error has occurred, please try again. If you're still having issues, please contact our support team.",
        ERROR,
      );
    }

    return Promise.reject(error);
  },
);

// Simple helper function for the proxy
// The proxy provides authenticated access to the API via the spark-dash-api
axiosInstance.sparkApi = (proxyData, postOptions = null) => {
  const envStore = useEnvStore();

  return axiosInstance.post(
    `/${envStore.siteId}/${envStore.siteEnv}/proxy-api`,
    proxyData,
    postOptions,
  );
}

export default axiosInstance;
