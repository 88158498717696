import { reactive } from 'vue';
import { v4 as uuid } from 'uuid';

export const ERROR = 'error';
export const WARNING = 'warning';
export const SUCCESS = 'success';
export const NOTICE = 'notice';

const store = reactive({
  notifications: [],
  defaultTimeout: 8000,

  notify(title, message = null, type = null, timeout = this.defaultTimeout) {
    const id = uuid();
    let timer;

    if (timeout) {
      timer = setTimeout(() => this.dismiss(id), timeout);
    }

    this.notifications.push({
      id,
      title,
      message,
      type,
      timeout: timer,
    });
  },

  dismiss(id) {
    const index = this.notifications.findIndex((notification) => notification.id === id);

    clearTimeout(this.notifications[index].timeout);
    this.notifications.splice(index, 1);
  },
});

export default store;
