import useEnvStore from '@/store/modules/env';
import notificationStore, { ERROR } from '@/store/modules/notifications';
import useSettingsStore from '@/store/modules/settings';
import api from '@/utils/api';
import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

export default defineStore('auth', {
  state: () => ({
    hasUserBeenLoaded: false,
    data: null,
  }),
  getters: {
    isLoggedIn: (state) => !!state.data?.user || false,
    user: (state) => state.data?.user,
    autoLoginRestrictedSession: (state) => state.data?.autoLoginRestrictedSession,
    name: (state) => state.data?.user?.name,
    firstName: (state) => state.data?.user?.name?.split(' ')[0] ?? state.data?.user?.name,
    isSparkStaff: (state) => state.data?.user?.is_spark_staff || false,
    email: (state) => state.data?.user?.email,
    acronymName: (state) => state.data?.user?.name?.match(/\b\w/g)
      ?.join('')
      .toUpperCase()
      .substring(0, 2),
    availableTeams: (state) => state.data?.availableTeams || [],
    availableTeamsAdvanced: (state) => state.data?.availableTeamsAdvanced || [],
    hasPermission: (state) => (permission) => state.data?.permissions?.[permission] || false,
  },
  actions: {
    setData(payload) {
      this.data = payload;
      this.hasUserBeenLoaded = true;
    },
    setAvailableTeamsAdvanced(payload) {
      this.data.availableTeamsAdvanced = payload;
    },
    async loadUser(payload) {
      if (!payload && this.hasUserBeenLoaded) {
        return;
      }
      try {
        const { data } = await api.get('/user');
        this.data = data;
        if (data.currentTeam) {
          if (data.currentTeam.slugged_name) {
            Sentry.setTag('site-id', data.currentTeam.slugged_name);
          }
        }
        // Intercom Setup
        if (data?.intercomOptions) {
          window.Intercom('boot', data.intercomOptions);
        }
        // Hubspot setup
        if (data?.user && !data.user.is_spark_staff) {
          const _hsq = window._hsq = window._hsq || [];
          _hsq.push(['identify', { email: data.user.email }]);
        }
      } catch (error) {
        console.error(error);
        this.data = null;
      }
    },
    async logout() {
      try {
        await api.post('/logout');
      } catch (error) {
        console.error(error);
      }

      this.$reset();
    },
    async changeSite(payload) {
      const envStore = useEnvStore();
      const settingsStore = useSettingsStore();
      try {
        await api.post('/change-site', { team_id: payload.teamId });
      } catch (e) {
        switch (e?.response?.status) {
          case 403:
            notificationStore.notify(
              'Error',
              e?.response?.data?.message || `Sorry, you don't have permission to access this site.`,
              ERROR,
            );
            break;
          case 404:
            notificationStore.notify(
              'Error',
              `Sorry, we couldn't find the site you're looking for. Please try again.`,
              ERROR,
            );
            break;
        }
      }
      this.loadUser(true);
      await envStore.$reset();
      await settingsStore.$reset();
    },
    async searchAvailableTeamsAdvanced(payload) {
      const { data } = await api.get('/search-available-teams-advanced', {
        params: {
          search: payload.search,
        },
      });
      this.setAvailableTeamsAdvanced(data);
    },
  },
});
